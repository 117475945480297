var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditMyCertifications" } },
            [_c("pga-save-button", { on: { click: _vm.Selects } })],
            1
          ),
          _c("pga-cancel-button", { on: { click: _vm.undo } }),
        ],
        1
      ),
      _c("pga-my-certifications-select", {
        attrs: { "is-loading": _vm.isLoading },
        model: {
          value: _vm.MyCertificationsSelects.Command,
          callback: function ($$v) {
            _vm.$set(_vm.MyCertificationsSelects, "Command", $$v)
          },
          expression: "MyCertificationsSelects.Command",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }