var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pga-data-table", {
    attrs: {
      title: "Certifications",
      headers: _vm.headers,
      data: _vm.listCertifications,
      loading: _vm.isLoading,
      "item-key": "Id",
      "show-select": "",
      "col-props": ["IsActive", "AttainmentDate", "ExpireDate"],
    },
    scopedSlots: _vm._u([
      {
        key: "column-IsActive",
        fn: function (props) {
          return [
            _c("pga-switch", {
              attrs: { readonly: "" },
              model: {
                value: props.row.item.IsActive,
                callback: function ($$v) {
                  _vm.$set(props.row.item, "IsActive", $$v)
                },
                expression: "props.row.item.IsActive",
              },
            }),
          ]
        },
      },
      {
        key: "column-AttainmentDate",
        fn: function (props) {
          return [
            _vm.showDate(props.row.item.Id)
              ? _c("pga-certification-date-picker", {
                  attrs: {
                    label: "Achievement Date",
                    id: props.row.item.Id,
                    "key-error": "AttainmentDate",
                    "row-key-error": "AttainmentDateCertificationIds",
                    errors: _vm.Errors,
                  },
                  on: { "change-date": _vm.setAttainmentDate },
                  model: {
                    value: props.row.item.AttainmentDate,
                    callback: function ($$v) {
                      _vm.$set(props.row.item, "AttainmentDate", $$v)
                    },
                    expression: "props.row.item.AttainmentDate",
                  },
                })
              : _vm._e(),
          ]
        },
      },
      {
        key: "column-ExpireDate",
        fn: function (props) {
          return [
            _vm.showDate(props.row.item.Id)
              ? _c("pga-certification-date-picker", {
                  attrs: {
                    label: "Expire Date",
                    "label-checkbox": "Expire?",
                    id: props.row.item.Id,
                    "key-error": "ExpireDate",
                    "row-key-error": "ExpireDateCertificationIds",
                    errors: _vm.Errors,
                    nullable: true,
                  },
                  on: { "change-date": _vm.setExpireDate },
                  model: {
                    value: props.row.item.ExpireDate,
                    callback: function ($$v) {
                      _vm.$set(props.row.item, "ExpireDate", $$v)
                    },
                    expression: "props.row.item.ExpireDate",
                  },
                })
              : _vm._e(),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectedMyCertifications,
      callback: function ($$v) {
        _vm.selectedMyCertifications = $$v
      },
      expression: "selectedMyCertifications",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }